//import Vue from 'vue'
//import Vuex from 'vuex'

//Vue.use(Vuex)

export default {
  state: {
    user: null,
    fullname : null,
    guid: null,
    username: null,
    permissions : 'fsg',
    priceAccess : 'fsg',
    loginStatus : null,
    company: 0,
    isNotApp : true,
    city : null,
    cityName : null,
    cityLat : 0,
    cityLong : 0,
    userCurrency : 'USD',
    userTourCurrency : 'USD',
    userLanguage : 'en',
    displayCurrency : '$',
    companycount : 0,
    searchname : null,  // hotel main search...
    searchpagetitle : null,
    searchlat : null,
    searchlong : null,
    searchid : null,
    hotdest : '',  // this is for hotels list to details page...
    sortby : 'S',
    stars : [false,false,false,false,false],
    rating : [false,false,false,false,false],
    ammen :{'fb':false,'sw':false,'fw':false,'fp':false,'pa':false,'ns':false,'as':false,'fc':false,'ac':false,'re':false,'ki':false},
    searchin : null,
    searchout : null,
    searchadult : 2,
    searchchild : 0,
    searchroom : 1,
    searchsort : 'T', // this is for tours
    searchpage : 1,
    tsearchname : null,
    tsearchpagetitle : null,
    tsearchid : null,
    cookie: null,
    currList: {"en": "English"},
    currTour: {"en": "English"},
    langList: {"USD": "United States Dollars (USD)"},
    hotelList: {},
    hotelCount: 0,
    mapPoints: [],
    mapLat: 0,
    mapLong: 0,
    mapZoom: 0,
    mapMinZoom : 0,
    mapMaxZoom : 17,
    isFullMap: false,
    fullMapId: 'topmap',
    hotelname: null,
    roomSite : null,
    roomSiteId : null,
    roomOtaId : null,
    roomKey : null,
    roomin : null,
    roomout : null,
    roomadult : 0,
    roomchild : 0,
    roomroom : 0,
    bookRpCode : null,
    bookRtCode : null,
    bookMisc : null,
    bookComplete : {},
    bookPopulate : {},
    isBookingComplete : false,
    memberInfo : {},
    promo : null,
    updateCC : false,
  },
  mutations: {
    UPDATE_USER(state, payload) {
      var keys = Object.keys(payload);
    //  console.log("error here m")
      if (keys.length)
      {
        keys.forEach(key => {
          if (key in state) { state[key] = payload[key]}
        })
      }
    },
  },
actions: {
  async UPDATE_USER({ commit }, payload) {
  //  console.log("error here act?")
    commit("UPDATE_USER", payload)
  }
},
  getters: {
    user: state => state.user,
    fullname: state => state.fullname,
    guid: state => state.guid,
    username: state => state.username,
    permissions : state => state.permissions,
    priceAccess : state => state.priceAccess,
    loginStatus : state => state.loginStatus,
    company: state => state.company,
    isNotApp : state => state.isNotApp,
    city : state => state.city,
    cityName : state => state.cityName,
    cityLat : state => state.cityLat,
    cityLong : state => state.cityLong,
    userCurrency : state => state.userCurrency,
    userTourCurrency : state => state.userTourCurrency,
    userLanguage : state => state.userLanguage,
    displayCurrency : state => state.displayCurrency,
    companycount: state => state.companycount,
    searchname : state => state.searchname,
    searchpagetitle : state => state.searchpagetitle,
    searchlat : state => state.searchlat,
    searchlong : state => state.searchlong,
    searchid : state => state.searchid,
    hotdest : state => state.hotdest,
    sortby : state => state.sortby,
    stars : state => state.stars,
    rating : state => state.rating,
    ammen : state => state.ammen, 
    searchin : state => state.searchin,
    searchout : state => state.searchout,
    searchadult : state => state.searchadult,
    searchchild : state => state.searchchild,
    searchroom : state => state.searchroom,
    searchsort : state => state.searchsort,
    searchpage : state => state.searchpage,
    tsearchname : state => state.tsearchname,
    tsearchpagetitle : state => state.tsearchpagetitle,
    tsearchid : state => state.tsearchid,
    cookie: state =>state.cookie,
    currList : state => state.currList,
    currTour : state => state.currTour,
    langList : state => state.langList,
    hotelList : state => state.hotelList,
    hotelCount : state => state.hotelCount,
    mapPoints : state => state.mapPoints,
    mapLat : state => state.mapLat,
    mapLong : state => state.mapLong,
    mapZoom : state => state.mapZoom,
    mapMinZoom : state => state.mapMinZoom,
    mapMaxZoom : state => state.mapMaxZoom,
    isFullMap : state => state.isFullMap,
    fullMapId : state => state.fullMapId,
    hotelname : state => state.hotelname,
    roomSite : state => state.roomSite,
    roomSiteId : state => state.roomSiteId,
    roomOtaId : state => state.roomOtaId,
    roomKey : state => state.roomKey,
    roomin : state => state.roomin,
    roomout : state => state.roomout,
    roomadult : state => state.roomadult,
    roomchild : state => state.roomchild,
    roomroom : state => state.roomroom,
    bookRpCode : state => state.bookRpCode,
    bookRtCode : state => state.bookRtCode,
    bookMisc : state => state.bookMisc,
    bookComplete :  state => state.bookComplete,
    bookPopulate : state => state.bookPopulate,
    isBookingComplete : state => state.isBookingComplete,
    memberInfo : state => state.memberInfo,
    promo : state => state.promo,
    updateCC :  state => state.updateCC,
  },
}
