<template>
   <!--  <Head>
      <meta name="what the fuck" content="this is fucking rediculous" />
      <link name="canonical" :content="getPath()" />
    </Head>-->
    <div>
        <header class="main_header_arae">
          <alert :active="alertVisible" @closing="(i) => alertVisible = i" :data="alertData" />
            <!-- Top Bar -->
            <div class="topbar-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-3 col-md-3 col-12" v-show="isShow">
                            <ul class="topbar-list">
                              <li>
                                <a href="https://www.facebook.com/FlyStayGo/" v-bind:target="urlDirct()"><i class="fab fa-facebook"></i></a>
                                <a href="https://twitter.com/flystaygo" v-bind:target="urlDirct()"><i class="fab fa-twitter-square"></i></a>
                                <a href="https://www.instagram.com/flystaygo/" v-bind:target="urlDirct()"><i class="fab fa-instagram"></i></a>
                                <a href="https://www.youtube.com/@flystaygo3535" v-bind:target="urlDirct()"><i class="fab fa-youtube"></i></a>
                               &nbsp;
                                <a href="https://play.google.com/store/apps/details?id=com.MajorCoupLLC.FlyStayGo&hl=en_US" v-bind:target="urlDirct()"><i class="fab fa-android"></i></a>
                                <a href="https://apps.apple.com/us/app/flystaygo/id1505373358" v-bind:target="urlDirct()"><i class="fab fa-apple"></i></a>
                              </li>
                                <!-- <li><a href="#!"><span>+1 725 201 7070</span></a></li> -->

                            </ul>

                        </div>
                        <div class="col-lg-9 col-md-9 col-12">
                            <ul class="topbar-others-options">
                                <li v-show="isShow && (!inProPath) && (isLogin==null || isLogin=='')">
                                  <div class="dropdown-center">
                                    <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside" >
                                      {{$t("signin")}}
                                    </button>
                                    <form class="dropdown-menu p-4" style="min-width: 300px;"  @submit.prevent="login()">
                                      <div class="mb-3">
                                        <label for="exampleDropdownFormEmail2" class="form-label">{{$t("email")}}</label>
                                        <input type="email" class="form-control" id="exampleDropdownFormEmail2" placeholder="email@example.com" v-model="username">
                                      </div>
                                      <div class="mb-3">
                                        <label for="exampleDropdownFormPassword2" class="form-label">{{$t("password")}}</label>
                                        <input type="password" class="form-control" id="exampleDropdownFormPassword2" v-bind:placeholder="$t('password')" v-model="password">
                                      </div>
                                      <div class="mb-3">
                                        <u>
                                          <router-link class="faux-b" to="/pro#/forgot-password">
                                            {{$t("forgot")}}
                                          </router-link>
                                        </u>

                                      </div>
                                      <button type="submit" class="btn btn-primary">{{$t("signin")}}</button>
                                      <p v-show="loginerror != null" class="discount">{{loginerror}}</p>
                                    </form>

                                  </div>
                                </li>
                                <li v-show="isLogin!=null && isLogin!=''" style="color:#fff;">{{$t('welcome')}}, {{isLogin}} <LogoutMain /></li>
                                <li>
                                    <div class="dropdown language-option">
                                        <select v-model="language" @change="changeLang()">
                                            <option v-for="(item,key) in langList" :value="key">{{item}}</option>

                                        </select>
                                    </div>
                                </li>
                                <li>
                                    <div class="dropdown language-option">

                                        <select v-model="currency" @change="changeCurr()" :key="currList">
                                          <option v-for="(item,key) in currList" :value="key">{{item}}</option>
                                        </select>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Navbar Bar -->
            <div class="navbar-area" :class="{ 'is-sticky': isSticky }" > <!--v-show="isMapOn" || isShow-->
                <div class="main-responsive-nav" v-show="isMapOn || isShow || (permissions=='pro' && isLogin!=null && isLogin!='')">
                    <div class="container">
                        <div class="main-responsive-menu mean-container">
                            <nav class="navbar">
                                <div class="container">
                                    <div class="logo" v-show="isShow">
                                        <router-link to="/">
                                            <img src="../assets/img/logo.png" alt="logo">
                                        </router-link>
                                    </div>
                                    <div class="mean-container-map" v-show="isMapOn"><i v-show="!isFullMap" v-on:click="toggleFullMap" class="fas fa-map-marked-alt"></i>
                                      <i v-show="isFullMap" v-on:click="toggleFullMap"  class="fas fa-h-square"></i>
                                    </div>
                                    <div class="mean-container-map" v-show="!isMapOn">
                                      &nbsp;
                                    </div>



                                    <span v-if="isLogin==null || isLogin==''"><a v-show="!inProPath && isShow" :href="redirectMember()" class="btn  btn_navber"><i class="fa fa-user-plus" aria-hidden="true"></i><!--<img src="@/assets/img/icon/user.png" width="25" />--></a></span>
                                    <span v-else-if="permissions=='fsg'"><a v-show="!inProPath" href="/pro#/login" class="btn  btn_navber"><i class="fa fa-credit-card" aria-hidden="true"></i></a></span>
                                    <router-link v-else-if="permissions=='pro' && isLogin!=null && isLogin!=''" to="/my-profile" class="btn  btn_navber btn_compact"><!--<i class="fas fa-user"></i>--><i class="fa fa-user" aria-hidden="true"></i><img src="@/assets/img/icon/pro.png" width="40" /></router-link>

                                    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                            data-bs-target="#navbar-content" v-show="isShow">
                                      <div class="hamburger-toggle">
                                        <div class="hamburger">
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </div>
                                      </div>
                                    </button>
                                    <div class="collapse navbar-collapse mean-nav" id="navbar-content" v-show="isShow">
                                      <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                                        <li class="nav-item">
                                          <router-link to="/" class="nav-link" :style="(path=='/') ? 'color : #cc6608 !important;' : ''">{{$t("hotels")}}</router-link>
                                        </li>
                                        <li class="nav-item">
                                          <a :href="cars" class="nav-link">{{$t("cars")}}</a>
                                        </li>
                                        <li class="nav-item">
                                          <a :href="flights" class="nav-link">{{$t("flights")}}</a>
                                        </li>
                                        <li class="nav-item">
                                          <router-link to="/tours" class="nav-link" :style="(path=='/tours') ? 'color : #cc6608 !important;' : ''">{{$t("tours")}}</router-link>
                                        </li>
                                        <li class="nav-item">
                                          <a :href="blogs" class="nav-link" >{{$t("blogs")}}</a>
                                        </li>
                                        <li v-show="isSearchBot" class="nav-item">
                                          <router-link to="/info/poi" class="nav-link" :style="(path=='/info/poi') ? 'color : #cc6608 !important;' : ''">POI</router-link>
                                        </li>

                                        <li class="nav-item">
                                          <router-link to="/info/contact" class="dropdown-item" :style="(path=='/info/contact') ? 'color : #cc6608 !important;' : ''">{{$t("Contactus")}}</router-link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="main-navbar" v-show="isShow">
                    <div class="container">
                        <nav class="navbar navbar-expand-md navbar-light">
                            <router-link class="navbar-brand" to="/">
                                <img src="../assets/img/logo.png" alt="logo">
                            </router-link>

                            <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                                <ul class="navbar-nav">
                                  <li class="nav-item">
                                    <router-link to="/" class="nav-link" :style="(path=='/') ? 'color : #ffcc33 !important;' : ''">{{$t("hotels")}}</router-link>
                                  </li>
                                  <li class="nav-item">
                                    <a :href="cars" class="nav-link">{{$t("cars")}}</a>
                                  </li>
                                  <li class="nav-item">
                                    <a :href="flights" class="nav-link">{{$t("flights")}}</a>
                                  </li>
                                  <li class="nav-item">
                                    <router-link to="/tours" class="nav-link" :style="(path=='/tours') ? 'color : #ffcc33 !important;' : ''">{{$t("tours")}}</router-link>
                                  </li>
                                  <li class="nav-item">
                                    <a :href="blogs" class="nav-link">{{$t("blogs")}}</a>
                                  </li>
                                  <li v-show="isSearchBot" class="nav-item">
                                    <router-link to="/info/poi" class="nav-link" :style="(path=='/info/poi') ? 'color : #cc6608 !important;' : ''">POI</router-link>
                                  </li>

                                    <li class="nav-item">
                                        <router-link to="/info/contact" class="nav-link" :style="(path=='/info/contact') ? 'color : #ffcc33 !important;' : ''">{{$t("Contactus")}}</router-link>
                                    </li>
                                </ul>
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                      <span v-if="isLogin==null || isLogin==''"><a v-show="!inProPath" :href="redirectMember()" class="btn  btn_navber">{{$t('becomemem')}}</a></span>
                                      <span v-else-if="permissions=='fsg'"><a v-show="!inProPath"  href="/pro#/login" class="btn  btn_navber">{{$t('renewmem')}}</a></span>
                                      <router-link v-else to="/my-profile" class="btn  btn_navber"><!--<i class="fas fa-user"></i>--><img src="@/assets/img/icon/pro.png" width="50"/>{{$t('userprof')}}</router-link>

                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
               <!-- <div class="others-option-for-responsive">
                    <div class="container">
                        <div class="container">
                            <div class="option-inner">
                                <div class="others-options d-flex align-items-center">
                                    <div class="option-item">
                                        <a href="#" class="search-box"><i class="fas fa-search"></i></a>
                                    </div>
                                    <div class="option-item">
                                        <router-link to="/info/contact" class="btn btn_navber">Get free quote</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </header>
    </div>
</template>



  }))


<script>

import store from '@/store'
import LogoutMain from '@/components/LogoutMain.vue'
import alert from '@/components/Alert.vue'
import { useHead } from '@unhead/vue'
import { nextTick } from 'vue'
let self;

export default {
  name: 'Header',
  components: {
    LogoutMain,
    alert
  },
    data() {
      return {
        alertVisible: false,
        alertData: null,
        isLogin : store.getters.fullname,
        permissions : store.getters.permissions,
        username : '',
        password : '',
        loginerror : null,
        language: store.getters.userLanguage,
        currency: store.getters.userCurrency,
        currList: store.getters.currList,
        langList: store.getters.langList,
        path : this.$route.path,
        pathChg : this.$route.path,
        isSticky: false,
        isShow : store.getters.isNotApp,
        isFullMap: false,
        isMapOn : false,
        isMobile : false,
        isShowMap :(this.$route.path.substring(0,13) == '/hotel-search'),
        inProPath : (this.$route.path.substring(0,4) == '/pro'),
        blogs: "/blog",
        isSearchBot : false,
        cars :'https://car.flystaygo.com',
        flights : 'https://air.flystaygo.com'
      /* flights : "https://secure.rezserver.com/flights/home/?refid=7513&refclickid=" + ((store.getters.username !== null) ? store.getters.username : 'NULL'),
        cars : "https://secure.rezserver.com/car_rentals/home/?refid=7513&refclickid=" + ((store.getters.username !== null) ? store.getters.username : 'NULL')*/
  }
  },
    setup(p,c) {
    nextTick (() => {
      useHead(() => ({
        title:['FlyStayGo Travel ' + self.$route.path.replace(/[-\/]/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())  ],
        // computed getter (recommended)
        meta: [{ name: 'description', content: () => 'FlyStayGo Travel Network: ' + self.$route.path.replace(/[-\/]/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()) },],
        link: [
        {
          rel: 'canonical',
          href: (self.$route.path.indexOf('/poipage') != -1) ? 'https://flystaygo.com' + self.$route.path.replace(/\/$/, '') + '?l=' + self.$route.query.l /*+ '&p=' + self.$route.query.p*/ :
                                                        'https://flystaygo.com' + self.$route.path.replace(/\/$/, '')
        },
        ],
      }))
    })
  },
  created(){
  self = this
  },
  watch: {
  '$route' (to, from) {
  this.path = this.$route.path
  this.isShowMap = (this.path.substring(0,13) == '/hotel-search')
  

  let size = window.innerWidth
  this.isMapOn = (size < 992 && this.isShowMap) ? true : false
  this.isMobile = (size < 992) ? true : false

        this.inProPath = (this.path.substring(0,4) == '/pro')
        this.changeRoute()
      },
      '$store.getters.isFullMap' : function() {
        this.isFullMap = store.getters.isFullMap
      },
      '$store.getters.fullname' : function () {
        this.isLogin = store.getters.fullname
      },
      '$store.getters.permissions' : function () {
        this.permissions = store.getters.permissions
      }
    /*  '$store.getters.guid' : function() {
        if (store.getters.guid == null) this.isLogin = null
      },
      '$store.getters.username' : function() {
        if (store.getters.username == null) this.isLogin = null
      }*/
    },

    mounted() {  //position: stick

       console.log("FFFFFFFFFFFFFFFF path " + this.$route.path)
      console.log("query " + this.$route.query.l + " " + this.$route.query.p) 

        window.addEventListener('scroll', () => {
            let scroll = window.scrollY
            if (scroll >= 200) {
                this.isSticky = true
            } else {
                this.isSticky = false
            }
        })
        let size = window.innerWidth
        this.isMobile = (size < 992) ? true : false
        this.isMapOn = (size < 992 && this.isShowMap) ? true : false

        window.addEventListener('resize', () => {
          let size = window.innerWidth
          this.isMobile = (size < 992) ? true : false
          this.isMapOn = (size < 992 && this.isShowMap) ? true : false
        })

        document.addEventListener('click', function (e) {
            // Hamburger menu
            if (e.target.classList.contains('hamburger-toggle')) {
                e.target.children[0].classList.toggle('active');
            }
        })

        var isCookieSave = false
        // check if we have a member variable
        if (this.$route.query.member)
        {
          var loginStr = this.$route.query.member.split(',')
          // if its not already in a cookie, then add it to the cookie
          if (store.getters.username != loginStr[0] || store.getters.guid != loginStr[1])
          {
            console.log("GUID MISMATCH--- " + store.getters.guid + " " + loginStr[1] )
            store.dispatch('UPDATE_USER', {
              username : loginStr[0],
              guid : loginStr[1],
              fullname : loginStr[2],
              permissions : loginStr[3],
              loginStatus : null
            })
            isCookieSave = true

          }
        }
        // check if we have a promo code...
        if (this.$route.query.promo)
        {
          store.dispatch('UPDATE_USER', {
            promo : this.$route.query.promo
          })
          this.alertData = this.$t('redeempromo')
          this.alertVisible = true

          isCookieSave = true
        }

        // this is the app
        if (this.$route.query.app && this.$route.query.app=='true')
        {
          this.isShow = false
          store.dispatch('UPDATE_USER', {
            isNotApp : this.isShow
          })
          isCookieSave = true
        }
        if (isCookieSave)  this.$urlRequest.cookies()


       // if (store.getters.loginStatus === null)
          this.changeLang() // call this to setup the language locally then call for the list from the server...
      //this.fetchList()
        this.changeRoute()


    },
  methods: {
   /* getPath()
    {
      return "https://flystaygo.com.com" + this.path
    },*/
    urlDirct()
    {
      return (this.isMobile) ? '_self' : '_blank'
    },
    redirectMember()
    {
      var ret = '/pro#/register'
      if (store.getters.promo != null && store.getters.promo != "SEOPROMOFSG") ret += "?promo=" + store.getters.promo
      return ret
    },
    login()
    {
      this.loginerror = null

      if (this.username != '' && this.password != '')
      {
        this.$urlRequest.login(/* values.account */'', this.username, this.password, false)
            .then(() => {

              var dropdown = document.querySelector(".p-4")
              if (dropdown !== null)
                dropdown.classList.remove("show")
              this.alertData = this.$t('alertsignin')
              this.alertVisible = true
              this.changeLang()

              // this.$router.push('auth/change-password')
            })
            .catch((error) => {
              //  this.$nprogress.done()
              this.loginerror = this.$t('loginerr')
            })
      }



    },
    logout () {
      console.log('UMM CHECKING LOGout?')
      this.loading = false
      this.$urlRequest.logout()
        .then(() => {
          //     this.loading = false;
        })
        .catch((error) => {
          //  this.$nprogress.done()
          //   this.loading = false;
        })
    },
    changeLang()
    {
      this.$i18n.locale = this.language
      this.fetchList()


    },
    toggleFullMap()
    {
      var target = (this.isFullMap) ? store.getters.fullMapId : "topmap"

      console.log("SCROLL BACK")
      var ref = document.getElementById(target);
      setTimeout(function () {
        console.log(ref)
        ref.scrollIntoView({
          behavior: "instant", //"smooth",
          block: "nearest",  // "center"  "start" " nearest"
        });
      }, 100);

      this.isFullMap = !this.isFullMap
      store.dispatch('UPDATE_USER', {
        isFullMap : this.isFullMap
      })
    },
    changeCurr()
    {
      console.log( "CurrPATH " + this.path )
      console.log("currency:" +this.currency)
      if (this.path == '/tours' || this.path == '/tour-search')
      {
        store.dispatch('UPDATE_USER', {
          userTourCurrency : this.currency
        })
        console.log("tours change")
      }
      else
      {
        store.dispatch('UPDATE_USER', {
          userCurrency: this.currency
        })
        console.log("hotel change")
      }

    },
    changeRoute()
    {
      console.log( "NEW PATH " + this.path )
      if (this.path == '/tours' || this.path == '/tour-search')
      {
        this.currency = store.getters.userTourCurrency
        this.currList = store.getters.currTour
      }
      else
      {

        this.currency = store.getters.userCurrency
        this.currList = store.getters.currList
      }

      var dropdown = document.querySelector(".p-4")
      if (dropdown !== null)
        dropdown.classList.remove("show")

      var hamburger = document.querySelector(".hamburger")
      if (hamburger !== null)
      {
        hamburger.classList.remove("active")
        var navCollapse = document.querySelector(".navbar-collapse")
        navCollapse.classList.remove("show")
      }
    },

    fetchList()
    {

      this.$urlRequest.post('fetch', 'menuList', {'userLanguage':this.language})    // {'text' : this.dest, 'type' : 'hotels'}
        .then((response) => {
          //   this.$nprogress.done()

          this.langList = response.hotels.languages
        //  if (store.getters.loginStatus === null)
         // {
            this.language = response.userLanguage
           // this.currency = response.userCurrency
          //}
          //else  // handle currency based on page...
         // {
            if (this.path == '/tours' || this.path == '/tour-search')
            {
              this.currList = response.tours.currencies
              if (response.loginStatus == "in")
                this.currency = response.toursCurrency
              else
                this.currency = store.getters.userTourCurrency
            }
            else
            {
              if (response.loginStatus == "in")
                this.currency = response.userCurrency
              else
                this.currency = store.getters.userCurrency
              this.currList = response.hotels.currencies
            }

            if (typeof response.isSearchBot !== 'undefined')
              this.isSearchBot = response.isSearchBot
          //}
            console.log("change login --- " + response.loginStatus)
            console.log("change LANG --- " + response.userLanguage)

          store.dispatch('UPDATE_USER', {
            permissions: response.accountType,
            priceAccess: response.priceAccess,
            loginStatus : response.loginStatus,
            fullname: response.fullName,
            userCurrency: response.userCurrency,
            userTourCurrency : response.toursCurrency,
            userLanguage: response.userLanguage,
            currList: response.hotels.currencies,
            currTour: response.tours.currencies,
            langList: response.hotels.languages
         //   loginStatus: response.loginStatus // get the login status as to get the value...
          })
          this.permissions = response.accountType
          console.log(response)

          console.log("fdsf ========" + store.getters.guid + store.getters.username + response.loginStatus + response.fullName)
          if (store.getters.guid != null && store.getters.username != null && response.loginStatus == 'in')
            this.isLogin = response.fullName;

          this.setpages()
          // this.$router.push('/login')
        })
        .catch((error) => {
          this.loaderActive = false;
          //this.loading = false
          // this.$nprogress.done()
          //this.$notification['warning']({
          // message: error.code,
          //  description: error.message
          //})
        })
    }
  }


}
</script>

