<template>
     <section id="common_banner">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h1 class="honeoverride">{{location}}</h1>
                        <ul>
                          <li id="search_banner">{{pageinfo}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>

import store from '@/store' 

export default {
  name: "BannerPages",
  data() {
    return {
      location : '',
      pageinfo : '',
    }
  },
  watch: {
    '$store.getters.searchname' : function () {
      this.location = (store.getters.searchname != null) ? store.getters.searchname : location
    },
    '$store.getters.searchpagetitle' : function () {
      this.pageinfo = (store.getters.searchpagetitle != null) ? store.getters.searchpagetitle : this.pageinfo
    }
  },
  mounted() { 
    var spl = this.$route.path.split('/')
    var loc = spl[spl.length-1].split('-')
    this.pageinfo = spl[spl.length-2].replace(/[-\/]/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase())
    this.location = ""
    for (let j = 1; j < loc.length; j++)
      this.location += (loc[j]!='p') ? loc[j] + ' ' : ''

  }
};
</script>